/*
 * Bullshit Job Title Generator
 * Originally from: https://codepen.io/TimPietrusky/pen/CxiHF
 *  
 * Inspired by https://github.com/bullgit/bullshit-job-titles
 * by bullgit.github.io
 * 
 * 2013 by Tim Pietrusky
 * timpietrusky.com
 */

BullshitJobTitleGenerator = (function() {

  function BullshitJobTitleGenerator(args) {
    try {
      // Parent element
      this.el = args.el;
      if (!args.setTitle) {
        this.el.title = "click me!";
      }

      this.firstPart = [
        'Web',
        'Internet',
        'Senior',
        'Lead',
        'Professional',
        'Junior',
        'Google',
        'Yahoo',
        'Flash',
        'Homepage',
        'Creative',
        'Future',
        'Dreamweaver',
        'Photoshop',
        'Joomla',
        'Frontpage',
        'HTML',
        'CSS',
        'Responsive',
        'WordPress',
        'CodePen',
        'Dribbble',
        'Internet Explorer',
        'GitHub',
        'Office',
        'Reddit',
        'NULL',
        'Undefined',
        'Error',
        'IT',
        'Marketing',
        'SEO',
        'Java',
        'C',
        'C++',
        'PHP',
        'Python',
        'Ruby',
        'SQL',
        'JavaScript',
        'Frontend',
        'Backend',
        'Chrome',
        'Safari',
        'Firefox',
        'Twitter',
        'HackerNews',
        'Social',
        'Linux',
        'Mac',
        'Windows',
        'Android',
        'iOS',
        'Hashtag',
        'Sidebar',
        'cssdeck',
        'JSFiddle',
        'jQuery',
        'Microsoft',
        'Ubuntu',
        'Generator',
        'Job Title Generator',
        'bullgit',
        '4ae9b8',
        'Kickstarter',
        'ebay',
        'Facebook',
        'Google+',
        'SCSS',
        'SASS',
        'LESS',
        'Stylus',
        'Coderwall',
        'Coderbits',
        'Mobile',
        'User Experience',
        'Soundcloud',
        'Node.js',
        'AngularJS',
        'Backbone',
        'React',
        'MongoDB',
        'YouTube',
        'Sublime Text',
        'Firefox OS',
        'Wikipedia',
        'Nerd',
        'Geek',
        'LinkedIn'
      ];

      this.secondPart = [
        'Expert',
        'Coffee Maker',
        'File Uploader',
        'Pizza Reheater',
        'Admin',
        'Search Expert',
        'Slayer',
        'Guru',
        'Designer',
        'Mastermind',
        'Millionaire',
        'RockStar',
        'Evangelist',
        'Master',
        'Celebrity',
        'Optimizer',
        'Bitch',
        'Hacker',
        'Dev',
        'Craftsman',
        'Jedi',
        'Sithlord',
        'Person',
        'Tweeter',
        'Slut',
        'Dick',
        'Boss',
        'CTO',
        'Lady',
        'Pirate',
        'Captain',
        'Legend',
        'Ninja',
        'Zombie',
        'King',
        'Supporter',
        'Manager',
        'Director',
        'Consultant',
        'Strategist',
        'Agent',
        'Wizard',
      ];

      this.preFirstPart = [
        'Über',
        'Ultra',
        'Freaking',
        'Holy',
        'Only the best',
        'Awesome',
        '+1',
        '.',
        'Insane',
        'First Class',
        'Unbelievable',
        'Gorgeous',
        'Beautiful',
        '.',
        '#1',
        'Sexy',
        'Hot',
        'One in a million',
        'Slick',
        'Brilliant',
        'Cute',
        'Nice',
        'Perfect'
      ];

      // Create a random job title on click
      this.el.addEventListener('click', function(e) {
        this.random();
      }.bind(this));

      // First title
      this.random();

    } catch (e) {}
  };

  // Create a random job title
  BullshitJobTitleGenerator.prototype.random = function() {
    try {
      var title = this.preFirstPart[this.randomNumber(0, this.preFirstPart.length - 1)];
      title += " " + this.firstPart[this.randomNumber(0, this.firstPart.length - 1)];
      title += " " + this.secondPart[this.randomNumber(0, this.secondPart.length - 1)];

      // Replace title in the DOM;
      this.el.textContent = title;
    } catch (e) {
      this.random();
    }
  };

  // Create a random number
  // min / max
  BullshitJobTitleGenerator.prototype.randomNumber = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return BullshitJobTitleGenerator;
})();

